import React, { useState, useEffect } from 'react';
import ScrollTop from '../components/global-components/BackToTop';
import Footer from '../components/global-components/Footer';
import About from '../components/section-components/About';
import Client from '../components/section-components/Client';
import Count from '../components/section-components/Count';
import Connect from '../components/section-components/Connect';
import News from '../components/section-components/New';
import Offer from '../components/section-components/Offer';
import Partner from '../components/section-components/Partner';
import Project from '../components/section-components/Project';
import Team from '../components/section-components/team-section/Team';
import Layouts from './../components/global-components/Layouts';
import Header from '../components/section-components/Header';
import useMain from '../providers/MainProvider';
import axios from 'axios';
import { baseUrl } from '../constans';
import { useTranslation } from "react-i18next";
import ServiceV2 from '../components/section-components/service-section/Service-v2';
import VideoHomeContainer from '../components/student-components/VideoHomeContainer';
import Training from '../components/section-components/training-section/Training';

const Home =()=> {
    const [data, setData] = useState()
    const { t } = useTranslation('common');
    const [contentLoading,setContentLoading] = useState(true)
    const {lng} = useMain()

    useEffect(() => {
        setContentLoading(true)
        axios.get(`${baseUrl}api/Home/Site?lng=${lng}`).then((res) => {   
            setData(res?.data)
            setContentLoading(false)
        })
    }, [lng])

   return(
    <Layouts pageTitle=''>
        <Header data={data} t={t}/>
        {data?.showAbout1 && <Offer data={data} t={t}/>}    
        {data?.showAbout2 && <About data={data}/>}
        {data?.showInfo && <Client data={data?.info} t={t}/>}
        <Training t={t} data={data?.trainings}  />
        {data?.showService && <ServiceV2 t={t} services={data?.services}  description={data?.serviceDescription}/>}
        {data?.showTeam && <Team employeeList={data?.employees} description={data?.teamDescription} t={t} isMain={true}/>}
        <Partner partners={data?.partnerImages}/>
        {data?.showProject && <Project projects={data?.projects} t={t} description={data?.projectDescription} /> }       
        {data?.showStudent && <VideoHomeContainer videos={data?.videos} t={t} description={data?.studentDescription} />}
        {/* <News news={data?.blogs} t={t} /> */}
        <Connect />
        <Footer />
        <ScrollTop />
    </Layouts>
    )
}
export default Home;