import { map } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { split } from 'lodash';

const SideBar = ({data,t}) => {    
    return (
        <div>
             <aside className="blog-sidebar">               
                <div className="single-item bg-blue recent-post mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                    <div className="item-title">
                        <h5>{t("News.OtherNews")}</h5>
                    </div>
                    <ul>
                        {map(data,(item, i)=>{
                            return (
                                <li key={i} className="media">
                                    <img src={item?.image} style={{objectFit: "cover"}} className="img-fluid" alt="Recent Post" />
                                    <div className="media-body">
                                        <span>{split(item?.createdDate,"T")[0]}</span>
                                        <Link to={`/news/${item?.pageId ||""}`}>{item?.name}</Link>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>              
            </aside>
        </div>
    );
}

export default SideBar;
