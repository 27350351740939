import axios from 'axios';
import { find } from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import LoadingCubes from '../components/section-components/loading-cubes/LoadingCubes';
import { baseUrl } from '../constans';
import i18next from 'i18next';
import { useHistory, useParams } from 'react-router-dom';

const MainContext = createContext({});

export const MainProvider = ({ children }) => {
    const [loading, setLoading] = useState(true)
    const { t, i18n } = useTranslation('common');
    const [data,setData] = useState()

    const [info, setInfo] = useState({})
    const [headers, setHeaders] = useState([])
    const [footerServices, setFooterServices] = useState([])
    const [footerPages, setFooterPages] = useState([])
    const [footerTest, setFooterTest] = useState("")
    const [address, setAddress] = useState("")

    const lng = (i18n.language && i18n.language != "en-US") ? i18n.language : "az";

    const handleData =()=>{   
        console.log("bura", lng, data?.translates)
        const translate = find(data?.translates,t=>t.lng == lng)
        const translateInfo = find(info?.translates,t=>t.lng == lng)
        setHeaders(translate?.headers)
        setFooterServices(translate?.footerServices)        
        setFooterTest(translate?.footerTest)
        setAddress(translateInfo?.address)
        data && setLoading(false)
    }

    const handleChangeLng = (l) => {
        i18n.changeLanguage(l)
    }

    useEffect(()=>{  
        handleData()
        if(i18n.language == "en-US") handleChangeLng("az") 
    },[i18n.language, data, info])

    useEffect(()=>{
        axios.get(`${baseUrl}api/Setting/SiteMain`).then((res) => {
            setData(res?.data)
            setInfo(res?.data?.data)
            setFooterPages(res?.data?.footerPages)           
        })
    },[])
    
    return (
        <MainContext.Provider
          value={{
            headers,
            footerServices,
            footerPages,
            footerTest,
            info,
            address,
            handleChangeLng,
            lng
          }}
        >
        <Helmet>
            <title>{info?.companyName}</title>
        </Helmet> 
        {loading ? <LoadingCubes/> : children}
        </MainContext.Provider>
    );
}

export default function useMain() {
    const context = useContext(MainContext);  
    return context;
  }
  