import React from 'react';

const AboutV2 = ({text}) => {  
    return (
        <>
            {/* <!-- start about area --> */}
            <section className="about p-120 index2">
                <div className="container">
                <p className='ql-editor' dangerouslySetInnerHTML={{__html: text}}/>
                </div>
            </section>
            {/* <!-- end about area --> */}
        </>
    );
}

export default AboutV2;
