import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../constans';
import { useTranslation } from "react-i18next";
import Layouts from '../components/global-components/Layouts';
import HeaderV3 from '../components/section-components/Header-v3';
import Footer from '../components/global-components/Footer';
import VideoContainer from '../components/student-components/VideoContainer';
import AboutV2 from '../components/section-components/About-v2'
import Connect from '../components/section-components/Connect';
import useMain from '../providers/MainProvider';

const Student = () => {
    const { t } = useTranslation('common');
    const [videos, setVideos] = useState([])
    const [text, setText] = useState("")
    const [pageCount, setPageCount] = useState()
    const [selectedPage, setSelectedPage] = useState(1)
    const [contentLoading,setContentLoading] = useState(true)
    const [loadMoreLoading,setLoadMoreLoading] = useState(false)
    const {lng} = useMain()


    useEffect(() => {
        setContentLoading(true)
        axios.get(`${baseUrl}api/Students/site?lng=${lng}&limit=6`).then((res) => {   
            setText(res?.data?.text)                 
            setVideos(res?.data?.videos?.values)           
            setPageCount(res?.data?.videos?.pageCount)
            setContentLoading(false)
        })
    }, [lng])

    useEffect(() => {
        if (selectedPage !== 1) {
            setLoadMoreLoading(true)
            axios.get(`${baseUrl}api/Students/videos?lng=az&page=${selectedPage}&limit=6`).then((res) => {
                setVideos([...videos, ...res.data.values])
                setLoadMoreLoading(false)
            })
        }
    }, [selectedPage])

    return (
        <>
            <Layouts pageTitle={t("Student.Title")}>
                <HeaderV3 title={t("Student.Title")} pageName={t("Student.PageName")} />               
                <AboutV2 text= {text}/>
                {videos &&
                    <VideoContainer
                        pageCount={pageCount}
                        contentLoading={contentLoading}
                        loadMoreLoading={loadMoreLoading}
                        setSelectedPage={setSelectedPage}
                        selectedPage={selectedPage}
                        videos={videos}                    
                    />}
                <Connect />
                <Footer />
            </Layouts>
        </>
    );
};

export default Student;