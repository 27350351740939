import { split } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useMain from '../../providers/MainProvider';



const PageTitle = (props) => {
    const {info} = useMain()
    const { t } = useTranslation('common');
    
    return (
        <>
             <section className="banner" style={{background:`url(${split(info.mainImage, " ")[0]})`}}>
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="banner-text text-center wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                                        <h1>{props.title}</h1>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to="/">{t("Page.Home")}</Link>
                                                </li>
                                              {
                                                props.pageName1 &&  
                                                <li className="breadcrumb-item active" aria-current="page">
                                                    <Link to={props.root1}>{props.pageName1}</Link>
                                                </li>
                                              } 
                                                <li className="breadcrumb-item active" aria-current="page">
                                                    {props.pageName}
                                                </li>
                                                
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </section>
        </>
    );
}

export default PageTitle;
