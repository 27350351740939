import React from 'react';

const About = ({data}) => {
    
    return (
        <div>
                {/* <!-- start about area --> */}
                <section className="about p-80">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">                                
                                <div className="text-part wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".4s">
                                <p className='ql-editor' dangerouslySetInnerHTML={{__html: data?.about2Description}}/> 
                                </div>                               
                            </div>
                            <div className="col-lg-6">
                                <div className="all-img mt-xs-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".4s">
                                    <div className="bg">
                                        <img src={data?.about2Image} className="img-fluid" alt="About" />
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- end about area --> */}
        </div>
    );
}

export default About;
