import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import map from 'lodash/map'
import LoadingDots from '../section-components/loading-dots/LoadingDots';
import ContentLoading from '../contentLoading/ContentLoading';
import { split } from 'lodash';

export const DEFAULT_ITEMS_COUNT = 6

const Service = ({ news, loadMoreLoading, pageCount, contentLoading, setSelectedPage, selectedPage,t}) => {
    const [offset, setOffset] = useState(0);
    const [hasEntered, setHasEntered] = useState(false);

    useEffect(() => {
        if (offset !== 0)
            setHasEntered(false);
    }, [offset]);

    useEffect(() => {
        if (hasEntered) {
            setOffset(prevOffset => prevOffset + DEFAULT_ITEMS_COUNT);
        }
    }, [hasEntered]);

    const handleFetch = () => {
        setSelectedPage(prev => prev + 1)
    }
    return (
        <div>
                {/* <!-- start service area --> */}
                {(news && !contentLoading) ? <section className={`service pt-120 pb-90 ${window.location.pathname !== '/about' ? "bg-blue": " "}`}>
                    <div className="container">
                        <div className="row">                            
                            <InfiniteScroll
                            dataLength={news.length}
                            next={handleFetch}
                            hasMore={pageCount > selectedPage}
                            loader={
                                !contentLoading && loadMoreLoading &&
                                < LoadingDots />
                            }
                        >
                        <div className="col-lg-12">
                            <div className="row">
                            {map(news, (item, i)=>{
                                    return <div className="col-lg-4 col-md-6" key={i}>
                                    <div className= 'item wow' data-wow-duration="1.5s" data-wow-delay=".4s">
                                        <div className="img-part">
                                            <img src={item?.image} className="img-fluid" alt="Service Img" />
                                        </div>
                                        <div className="content">                                               
                                            <h4>{item?.title} <span>{split(item?.createdDate,"T")[0]}</span></h4>
                                            <p>{item?.shortDescription || ""}</p>                                           
                                            <Link to={`/blog/${item?.pageId ||""}`}>{t("News.Details")} <i className="fas fa-long-arrow-alt-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            })}
                            </div>
                        </div>
                        </InfiniteScroll>
                        </div>
                    </div>
                </section>
                 :
                 <ContentLoading />
             }           
                {/* <!-- end service area --> */}
        </div>
    );
}

export default Service;
