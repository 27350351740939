import React,{ useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
import { Link } from 'react-router-dom';
import { sectionData } from './../../data/section.json';
import split from 'lodash/split';

const reg = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

function Hero ({data,t}){
    const [isOpen, setOpen] = useState(false)

    const handleYoutubeId=(url)=>{
        const matchs = url.match(reg) 
        return matchs ? matchs[1]:""
    }
    
    return(
        <>
        {/* <!-- start banner area --> */}
        <section className="banner" style={{backgroundImage:`url(${split(data?.mainImage, " ")[0]})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            backgroundSize: "100%"}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="banner-text wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                            <h1 style={{color:data?.mainTextColor}}>{data?.mainTitle}</h1>
                            <p style={{color:data?.mainTextColor}}>
                                {data?.mainDescription}
                            </p>
                            <ul className="d-flex">
                                <li>
                                    <Link to="/about" className="btn-style"><span style={{color:data?.mainTextColor}}>{t("Home.AboutUsButton")}</span></Link>
                                </li>
                                <li>
                                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={handleYoutubeId(data?.mainVideo|| "")} onClose={() => setOpen(false)} />
                                     <Link to="#!" className="special-btn d-flex align-items-center" onClick={()=>{setOpen(true)}}>
                                        <div className="vid-btn">
                                            <span className="btn-inner"><i className="fas fa-play"></i></span>
                                        </div>
                                        {t("Home.HowWeWork")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>           
        </section>
        {/* <!-- end banner area --> */}

        </>

    )
}

export default Hero;