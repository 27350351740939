import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AboutPage from '../../page/AboutPage';
import Home from '../../page/Home';
import ProjectPage from '../../page/ProjectPage';
import ServiceDetails from '../../page/ServiceDetails';
import TrainingDetails from '../../page/TrainingDetails';
import ServicePage from '../../page/ServicePage';
import TeamDetails from '../../page/TeamDetails';
import TeamPage from '../../page/TeamPage';
import ErrorPage from '../../page/Error.jsx'
import ContactPage from '../../page/ContactPage';
import BlogDetails from '../../page/BlogDetails';
import PhotoGalery from '../../page/PhotoGalery';
import DynamicPages from '../../page/Pg'
import Student from '../../page/Student';
import Calculator from '../../page/Calculator';
import BlogList from '../../page/BlogList';


function AppRoute() {

 

    return (
        < BrowserRouter >
            < Switch >
                < Route path = '/' exact component = { Home } />
                < Route path = '/haqqimizda/tariximiz' exact component = { AboutPage } />
                < Route path = '/xidmetler' exact component = { ServicePage } /> 
                < Route path = '/xidmetler/:id/:itemId'  component = { ServiceDetails } />
                < Route path = '/xidmetler/:id'  component = { ServiceDetails } />   
                < Route path = '/telimler/:id'  component = { TrainingDetails } />
                < Route path = '/telimler'  component = { TrainingDetails } />           
                < Route path = '/foto-qalereya/'  component = { PhotoGalery } />
                < Route path = '/haqqimizda/layiheler' exact component = { ProjectPage } />
                < Route path = '/telebeler' exact component = { Student } />               
                < Route path = '/haqqimizda/emekdaslar/:id' component = { TeamDetails } />
                < Route path = '/haqqimizda/emekdaslar' exact component = { TeamPage } />
                < Route path = '/blog' exact component = { BlogList } />
                < Route path = '/blog/:pageId' exact component = { BlogDetails } />
                < Route path = '/elaqe' exact component = { ContactPage } />
                < Route path = '/qiymetler/:id' exact component = { Calculator } />
                < Route path = '/pg/:id' exact component = { DynamicPages } />
                < Route path = '/notfound' exact component = { ErrorPage } />

                < Route path = '/:lng/' exact component = { Home } />
                < Route path = '/:lng/haqqimizda/tariximiz' exact component = { AboutPage } />
                < Route path = '/:lng/xidmetler' exact component = { ServicePage } />
                < Route path = '/:lng/xidmetler/:id'  component = { ServiceDetails } />
                < Route path = '/:lng/xidmetler/:id/:itemId'  component = { ServiceDetails } />
                < Route path = '/:lng/telimler/:id'  component = { TrainingDetails } />
                < Route path = '/:lng/telimler'  component = { TrainingDetails } />
                < Route path = '/:lng/foto-qalereya/'  component = { PhotoGalery } />
                < Route path = '/:lng/haqqimizda/layiheler' exact component = { ProjectPage } />
                < Route path = '/:lng/telebeler' exact component = { Student } />
                < Route path = '/:lng/haqqimizda/emekdaslar/:id' component = { TeamDetails } />
                < Route path = '/:lng/haqqimizda/emekdaslar' exact component = { TeamPage } />
                < Route path = '/:lng/blog' exact component = { BlogList } />
                < Route path = '/:lng/blog/:pageId' exact component = { BlogDetails } />
                < Route path = '/:lng/elaqe' exact component = { ContactPage } />
                < Route path = '/:lng/qiymetler/:id' exact component = { Calculator } />
                < Route path = '/:lng/pg/:id' exact component = { DynamicPages } />
                < Route path = '/:lng/notfound' exact component = { ErrorPage } />

                < Route path = '*' exact component = { ErrorPage } />
            </ Switch> 
        </ BrowserRouter>
    )
        
    
}
export default AppRoute;