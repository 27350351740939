import React, { useEffect, useState } from 'react';
import GaleryHeader from './GaleryHeader';
import styles from './styles.module.css'
import InfiniteScroll from 'react-infinite-scroll-component';
import map from 'lodash/map'
import LoadingDots from '../section-components/loading-dots/LoadingDots';
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import ContentLoading from '../contentLoading/ContentLoading';

export const DEFAULT_ITEMS_COUNT = 20;


const GaleryContainer = ({ images, loadMoreLoading, pageCount, contentLoading, setSelectedPage, selectedPage, t, categories, selectedCategory, setSelectedCategory }) => {
    const [offset, setOffset] = useState(0);
    const [hasEntered, setHasEntered] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [main, setMain] = useState(0);

    useEffect(() => {
        if (offset !== 0)
            setHasEntered(false);
    }, [offset]);

    useEffect(() => {
        if (hasEntered) {
            setOffset(prevOffset => prevOffset + DEFAULT_ITEMS_COUNT);
        }
    }, [hasEntered]);

    const rowRenderer = ({ index, style }) => {
        const item = images[index];
        return (
            <div onClick={() => {
                setMain(index);
                setIsOpen(true);
            }}
                className="col-lg-4 col-md-6" >
                <div className={`item wow ${styles.ImageContainer}`} data-wow-duration="1.5s" data-wow-delay=".3s">
                    <img src={item?.image} className="img-fluid" alt={item?.imageName} />
                </div>
            </div>
        );
    };

    const handleFetch = () => {
        setSelectedPage(prev => prev + 1)
    }
    return (
        <div className={styles.GaleryContainer}>
            {categories && <GaleryHeader categories={categories} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />}
            {(images && !contentLoading) ? <div className="col-lg-12">
                <div className="row">
                    <div>
                        {/* <InfiniteScroll
                            dataLength={images.length}
                            next={handleFetch}
                            hasMore={pageCount > selectedPage}
                            loader={
                                !contentLoading && loadMoreLoading &&
                                < LoadingDots />
                            }
                        > */}
                            <div className="col-lg-12">
                                <div className="row">
                                    {map(images, (item, index) => rowRenderer({
                                        index,
                                        style: {},
                                    }))
                                    }
                                </div>
                            </div>
                           {
                                (pageCount > selectedPage) && 
                                    (!contentLoading && loadMoreLoading ? 
                                    <LoadingDots /> : 
                                    <button className={styles.ShowMore} type='button' onClick={()=>handleFetch()}>
                                        {t("PhotoGallery.ShowMore")}
                                    </button>)                                
                           }
                        {/* </InfiniteScroll> */}
                    </div>
                </div>
            </div> :
                <ContentLoading />
            }
            {isOpen && <Lightbox
                mainSrc={images[main].image}
                nextSrc={images[(main + 1) % images.length]}
                prevSrc={
                    images[
                        (main + images.length - 1) % images.length
                    ].image
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                    setMain(
                        (main + images.length - 1) % images.length
                    )
                }
                onMoveNextRequest={() =>
                    setMain((main + 1) % images.length)
                }
            />
            }
        </div>
    );

};

export default GaleryContainer;