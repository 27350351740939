import React, { useState, useEffect } from 'react';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import Connect from '../components/section-components/Connect';
import BlogDetailsInner from '../components/blog-components/BlogDetails'
import HeaderV3 from '../components/section-components/Header-v3';
import { useHistory, useParams } from 'react-router-dom';
import useMain from '../providers/MainProvider';
import axios from 'axios';
import { baseUrl } from '../constans';
import { useTranslation } from "react-i18next";

const BlogDetails = () => {
    const [news, setNews] = useState()
    const { t } = useTranslation('common');
    const [contentLoading,setContentLoading] = useState(true)
    const {lng} = useMain()
    const { pageId } = useParams();
    let history = useHistory();

    useEffect(() => {
        setContentLoading(true)
        axios.get(`${baseUrl}api/BlogPages/site/${pageId}?lng=${lng}&limit=6`).then((res) => {   
            setNews(res?.data)
            setContentLoading(false)
        }).catch((err)=>{
            history.push('/notfound')
        })
        window.scrollTo(0,0);
    }, [lng,pageId])


    return (
       <Layouts  pageTitle={news?.name}>
           <HeaderV3 title={news?.name}  pageName={news?.name} pageName1={t("News.PageName")} root1={"/news"}  />
           <BlogDetailsInner data={news} t={t}/>
           <Connect />
           <Footer />
       </Layouts>
    );
}

export default BlogDetails;
