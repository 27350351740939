import React, { useEffect, useState } from 'react';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import HeaderV3 from '../components/section-components/Header-v3';
import ProjectV2 from '../components/section-components/project-section/Project-v2';
import axios from 'axios';
import { baseUrl } from '../constans';
import { useTranslation } from "react-i18next";
import Connect from '../components/section-components/Connect';
import useMain from '../providers/MainProvider';

const ProjectPage = () => {
    const [projectData, setProjectData] = useState({})
    const [isEnded, setIsEnded] = useState(true)
    const [selectedPage, setSelectedPage] = useState(1)
    const [contentLoading, setContentLoading] = useState(false)
    const [loadMoreLoading, setLoadMoreLoading] = useState(false)
    const { t } = useTranslation('common');
    const {lng} = useMain()


    useEffect(() => {
        setContentLoading(true)
        console.log('bura 1')
        axios.get(`${baseUrl}api/Projects/Site?ended=true&lng=${lng}&page=1&limit=6`).then((res) => {
            setProjectData({
                [isEnded]: res.data.values,
                ['page' + isEnded]: selectedPage,
                ['pageCount' + isEnded]: res.data.pageCount
            })
            setContentLoading(false)
        })
    }, [lng])

    useEffect(() => {
        setSelectedPage(projectData["page" + isEnded] || 1)
        if (!projectData[isEnded]?.length > 0) {
            setContentLoading(true)
            axios.get(`${baseUrl}api/Projects/Site?ended=${isEnded}&lng=${lng}&page=${projectData["page" + isEnded]}&limit=6`).then((res) => {
                setProjectData({
                    ...projectData,
                    [isEnded]: res.data.values,
                    ['page' + isEnded]: selectedPage
                })
                setContentLoading(false)
            })
        }
    }, [isEnded])

    useEffect(() => {
        if (selectedPage != 1 && projectData[isEnded]) {
            setLoadMoreLoading(true)
            axios.get(`${baseUrl}api/Projects/Site?ended=${isEnded}&lng=${lng}&page=${selectedPage}&limit=6`).then((res) => {
                console.log(isEnded, selectedPage, res.data.values, 'bura2')
                setProjectData({
                    ...projectData,
                    [isEnded]: [...projectData[isEnded], ...res.data.values],
                    ['page' + isEnded]: selectedPage
                })
                setLoadMoreLoading(false)
            })
        }
    }, [selectedPage])



    return (
        <>
            <Layouts pageTitle={t("Project.PageName")}>
                <HeaderV3 title={t("Project.Title")} pageName={t("Project.PageName")} />
                <ProjectV2
                    isEndedProject={isEnded}
                    projects={projectData[isEnded]}
                    setIsEnded={setIsEnded}
                    setSelectedPage={setSelectedPage}
                    selectedPage={projectData['page' + isEnded]}
                    pageCount={projectData['pageCount' + isEnded]}
                    loadMoreLoading={loadMoreLoading}
                    contentLoading={contentLoading}
                    t={t}
                />
                 <Connect />
                <Footer />
            </Layouts>
        </>
    );
}

export default ProjectPage;
