import React,{useEffect, useState} from 'react';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import Connect from '../components/section-components/Connect';
import HeaderV3 from '../components/section-components/Header-v3';
import ContactInner from '../components/section-components/ContactInner';
import axios from 'axios';
import { baseUrl } from '../constans';
import { useTranslation } from 'react-i18next';
import useMain from '../providers/MainProvider';

const ContactPage = () => {
    const [contactData,setContactData] = useState()
    const { t } = useTranslation('common');
    const {lng} = useMain()

    useEffect(() => {
        axios.get(`${baseUrl}api/Setting/Contact?lng=${lng}`).then((res)=>{
            setContactData(res.data)
        })
        window.scrollTo(0,0);
}, [])
    return (
        <Layouts pageTitle={t("Contact.PageName")}>
            <HeaderV3 title={t("Contact.Title")} pageName={t("Contact.PageName")} />
            <ContactInner data={contactData} t={t} />
            {/* <Map let={mapData.let} lng={mapData.lng} zoom={mapData.zoom} mapLetLng={mapData.mapLetLng.center} /> */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3038.920127878597!2d49.8567308!3d40.3884628!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d834955bdd7%3A0x792d521b4d2045df!2sAzeraudit%20Konsaltinq!5e0!3m2!1str!2saz!4v1695035591896!5m2!1str!2saz" 
                width="100%" height="450" 
                styles={{border:0}} allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">                
            </iframe>
            <Connect />
            <Footer />
        </Layouts>
    );
}

export default ContactPage;
