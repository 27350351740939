import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import map from 'lodash/map'
import LoadingDots from '../loading-dots/LoadingDots';
import styles from './styles.module.css'
import ContentLoading from '../../contentLoading/ContentLoading'
import { split } from 'lodash';

export const DEFAULT_ITEMS_COUNT = 20;

const ProjectV2 = ({ isEndedProject, pageCount, loadMoreLoading, contentLoading, selectedPage, projects, setIsEnded, setSelectedPage ,t}) => {

    const [offset, setOffset] = useState(0);
    const [hasEntered, setHasEntered] = useState(false);

    useEffect(() => {
        if (offset !== 0)
            console.log('update')
        setHasEntered(false);
    }, [offset]);

    useEffect(() => {
        if (hasEntered) {
            setOffset(prevOffset => prevOffset + DEFAULT_ITEMS_COUNT);
        }
    }, [hasEntered]);

    const rowRenderer = ({ index, style }) => {
        const item = projects[index];
        return (
            <div key={index} className="col-lg-4 col-md-6" >
                <div className={`item wow `} data-wow-duration="1.5s" data-wow-delay=".3s">
                    <img src={item?.image} className="img-fluid" alt="Project" />
                    <div className="action">
                        <span>{item?.name}</span><br/>
                        {!isEndedProject ? <span>{t("Project.StartDate")} : {split(item?.startDate,"T")[0]}</span> :
                        <span>{split(item?.startDate,"T")[0]} - {split(item?.endDate,"T")[0]}</span>}                                           
                    </div>
                </div>
            </div>
        );
    };

    const handleFetch = () => {
        setSelectedPage(prev => prev + 1)
    }

    return (
        <>
            {/* <!-- start project area --> */}
            <section className={`project pt-120 pb-90 project-style2 ${window.location.pathname === '/project' ? " " : "bg-blue"}`}>
                <div className="container">
                    <div className="row">
                        <div className={styles.CategoryContainer + " col-lg-8 offset-lg-2"}>
                            <div className="section-title text-center wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                                <span className={isEndedProject && styles.Selected} onClick={() => { !isEndedProject && setIsEnded(true) }}>{t("Project.Ended")}</span> 
                                <span className={!isEndedProject && styles.Selected} onClick={() => { isEndedProject && setIsEnded(false) }}>{t("Project.NotEnded")}</span> 
                            </div>
                        </div>
                    </div>
                    {!contentLoading ? <div className="col-lg-12">
                        <div className="row">
                            <div>
                                {console.log(projects?.length)}
                                <InfiniteScroll
                                    dataLength={projects?.length || 0}
                                    next={handleFetch}
                                    hasMore// ={pageCount>selectedPage}
                                    loader={
                                        !contentLoading && loadMoreLoading &&
                                        <LoadingDots />
                                    }
                                >
                                    <div className="col-lg-12">
                                        <div className="row">
                                            { }
                                            {map(projects, (item, index) => rowRenderer({
                                                index,
                                                style: {},
                                            }))
                                            }
                                        </div>
                                    </div>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div> : <ContentLoading />}
                </div>
            </section>
            {/* <!-- end project area --> */}
        </>
    );
}

export default ProjectV2;
