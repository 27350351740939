import React, { useEffect, useState } from 'react';
import { useHistory , useParams } from 'react-router-dom';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import HeaderV3 from '../components/section-components/Header-v3';
import ServiceDetailsInner from '../components/Service-components/Service-details';
import axios from 'axios';
import { baseUrl } from '../constans';
import { useTranslation } from "react-i18next";
import LoadingCubes from '../components/section-components/loading-cubes/LoadingCubes';
import { find, parseInt } from 'lodash';
import Connect from '../components/section-components/Connect';
import useMain from '../providers/MainProvider';

const ServiceDetails = () => {
    const [serviceData,setServiceData] = useState()
   
    let history = useHistory();
    const [loading, setLoading]= useState(false);
    const {lng} = useMain()

    const { id, itemId } = useParams();    
    const { t } = useTranslation('common');

    console.log(itemId)

    useEffect(() => {        
        window.scrollTo(0, 0);
        setLoading(true)
      
        axios.get(`${baseUrl}api/Services/Site/${id}?lng=${lng}`).then((res) => {
            setServiceData(res.data)            
            setLoading(false)
        }).catch((err)=>{
            history.push('/notfound')
        })
      
    }, [id,lng])
    
    const data = find(serviceData?.serviceItems, s=> s?.root === (itemId || serviceData?.serviceItems[0]?.root))

    return loading? <LoadingCubes/>:  (
        <>
            <Layouts pageTitle={data?.name}>
                <HeaderV3 title={serviceData?.name}  pageName={serviceData?.name} pageName1={t("Service.PageName")} root1={"/xidmetler"}  />
                {serviceData && <ServiceDetailsInner id={id} openedTab={itemId || serviceData?.serviceItems[0]?.root} serviceData={serviceData} t={t}/>}
                <Connect />
                <Footer />
            </Layouts>
        </>
    );
}

export default ServiceDetails;
