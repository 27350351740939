import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layouts from '../components/global-components/Layouts';
import Footer from '../components/global-components/Footer';
import HeaderV3 from '../components/section-components/Header-v3';
import axios from 'axios';
import { baseUrl } from '../constans';
import { useHistory } from "react-router-dom";
import Connect from '../components/section-components/Connect';
import useMain from '../providers/MainProvider';
import CalculatorInputs from '../components/calculator-component/CalculatorInputs';
import AboutV2 from '../components/section-components/About-v2'
import { useTranslation } from 'react-i18next';
import { forEach, sortBy } from 'lodash';


const Calculator = () => {
    const { id } = useParams();
    let history = useHistory();
    const [pageData, setPageData] = useState()
    const { lng } = useMain()
    const { t } = useTranslation('common');

    useEffect(() => {
        axios.get(`${baseUrl}api/CalculatorPages/Site/${id}?lng=${lng}`).then((res) => {
        if(res.data){
            const data = res?.data;
            forEach(data?.inputs, input=>{
                if(input?.inputType == "radio") input.options = sortBy(input?.options,["value"])
            })
            setPageData(data)
        }            
        }).catch((err) => {           
           history.push('/notfound')            
        })
        
    }, [id, lng])

    return (
        <Layouts style={{ background: "#f7fafe" }} pageTitle={pageData?.title}>
            <HeaderV3 title={pageData?.title} pageName={pageData?.name} />
            <AboutV2 text={pageData?.description} />
            <CalculatorInputs inputs={pageData?.inputs} name={pageData?.name} t={t}/>
            <Connect />
            <Footer />
        </Layouts>
    );
};

export default Calculator;