import React, { useEffect, useState } from 'react';
import styles from './styles.module.css'
import InfiniteScroll from 'react-infinite-scroll-component';
import map from 'lodash/map'
import LoadingDots from '../section-components/loading-dots/LoadingDots';
import "react-image-lightbox/style.css";
import ContentLoading from '../contentLoading/ContentLoading';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
import { baseUrl } from '../../constans';

export const DEFAULT_ITEMS_COUNT = 6

const reg = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

const VideoContainer = ({ videos, loadMoreLoading, pageCount, contentLoading, setSelectedPage, selectedPage}) => {
    const [offset, setOffset] = useState(0);
    const [isOpen, setOpen] = useState(false)
    const [url, setUrl] = useState('');
    const [type, setType] = useState('');
    const [hasEntered, setHasEntered] = useState(false);

    const handleYoutubeId=(url)=>{
        const matchs = url.match(reg) 

        return matchs[1]
    }

    const handleVideo=(video)=>{
        if(video.type == 'youtube'){           
            setUrl(handleYoutubeId(video.url))
        }
        else {
            setUrl(`${baseUrl}api/Students/playvideo/${video.id}`)           
        }
        setType(video.type) 
        setOpen(true)       
    }

    useEffect(() => {
        if (offset !== 0)
            setHasEntered(false);
    }, [offset]);

    useEffect(() => {
        if (hasEntered) {
            setOffset(prevOffset => prevOffset + DEFAULT_ITEMS_COUNT);
        }
    }, [hasEntered]);

    const rowRenderer = ({ index, style }) => {
        const item = videos[index];
        return (
            <div onClick={()=>handleVideo(item)}
                className="col-lg-4 col-md-6" >
                <div className={`item wow ${styles.ImageContainer}`} data-wow-duration="1.5s" data-wow-delay=".3s">
                   {item.type == 'youtube'? <img className="img-fluid" src={`https://i.ytimg.com/vi_webp/${handleYoutubeId(item.url)}/sddefault.webp`}  alt={item?.name} /> :
                    <video className="img-fluid">
                        <source src={`${baseUrl}api/Students/playvideo/${item.id}`} type="video/mp4"/>
                    </video> 
                    }                   
                </div>
            </div>
        );
    };

    const handleFetch = () => {
        setSelectedPage(prev => prev + 1)
    }
    return (
        <div className={styles.GaleryContainer}>  
        <ModalVideo autoplay={true} isOpen={isOpen} videoId={url} onClose={() => setOpen(false)} >
        {
            type == 'video' &&
            <video className={styles.Video} autoPlay controls>
                <source src={url} type="video/mp4"/>
            </video>
        }        
        </ModalVideo>
            {(videos && !contentLoading) ? <div className="col-lg-12">
                <div className="row">
                    <div>
                        <InfiniteScroll
                            dataLength={videos.length}
                            next={handleFetch}
                            hasMore={pageCount > selectedPage}
                            loader={
                                !contentLoading && loadMoreLoading &&
                                < LoadingDots />
                            }
                        >
                            <div className="col-lg-12">
                                <div className="row">
                                    {map(videos, (item, index) => rowRenderer({
                                        index,
                                        style: {},
                                    }))
                                    }
                                </div>
                            </div>
                        </InfiniteScroll>
                    </div>
                </div>
            </div> :
                <ContentLoading />
            }           
        </div>
    );

};

export default VideoContainer;