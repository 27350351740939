import React, { useState } from 'react';
import styles from './styles.module.css'
import map from 'lodash/map'
import "react-image-lightbox/style.css";
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
import { baseUrl } from '../../constans';
import SectionTitle from '../global-components/SectionTitle';

export const DEFAULT_ITEMS_COUNT = 6

const reg = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

const VideoHomeContainer = ({ videos, t, description }) => {
    const [isOpen, setOpen] = useState(false)
    const [url, setUrl] = useState('');
    const [type, setType] = useState('');

    const handleYoutubeId = (url) => {
        const matchs = url.match(reg)

        return matchs[1]
    }

    const handleVideo = (video) => {
        if (video.type == 'youtube') {
            setUrl(handleYoutubeId(video.url))
        }
        else {
            setUrl(`${baseUrl}api/Students/playvideo/${video.id}`)
        }
        setType(video.type)
        setOpen(true)
    }

    const rowRenderer = ({ index, style }) => {
        const item = videos[index];
        return (
            <div onClick={() => handleVideo(item)}
                className="col-lg-4 col-md-6" >
                <div className={`item wow ${styles.ImageContainer}`} data-wow-duration="1.5s" data-wow-delay=".3s">
                    {item.type == 'youtube' ? <img className="img-fluid" src={`https://i.ytimg.com/vi_webp/${handleYoutubeId(item.url)}/sddefault.webp`} alt={item?.name} /> :
                        <video className="img-fluid">
                            <source src={`${baseUrl}api/Students/playvideo/${item.id}`} type="video/mp4" />
                        </video>
                    }
                </div>
            </div>
        );
    };

    return (
        <section className="p-80">
            <div className="container">
            <ModalVideo autoplay={true} isOpen={isOpen} videoId={url} onClose={() => setOpen(false)} >
                {
                    type == 'video' &&
                    <video className={styles.Video} autoPlay controls>
                        <source src={url} type="video/mp4" />
                    </video>
                }
            </ModalVideo>
             <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <SectionTitle title={t("Home.Videos")} titleContent={description} />
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                            {map(videos, (item, index) => index < 6 && rowRenderer({
                                index,
                                style: {},
                            }))
                            }
                        </div>

                    </div>
                </div>
            </div>            
       </div>
        </section>        
    );
};

export default VideoHomeContainer;