import React from 'react';
import styles from './styles.module.css'

const LoadingCubes = () => {
    return (
        <div className={styles.spinner_wrapper_1}>
         <div className={styles.spinner}>
          <div className={styles.sk_folding_cube}>
           <div className={styles.sk_cube1 + " " +styles.sk_cube}></div>
           <div className={styles.sk_cube2 + " " +styles.sk_cube}></div>
           <div className={styles.sk_cube4 + " " +styles.sk_cube}></div>
           <div className={styles.sk_cube3 + " " +styles.sk_cube}></div>
          </div>
         </div>
        </div>
    );
};


export default LoadingCubes;