import { React } from 'react';
import AppRoute from '../components/route-components/Approute';
import common_az from '../locales/az/common.json'
import common_ru from '../locales/ru/common.json'

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { MainProvider } from '../providers/MainProvider';
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' }
}

function App() {
  i18next
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: true },  // React already does escaping
    detection: options,                      // language to use
    resources: {
      az: {
        common: common_az               // 'common' is our custom namespace
      },
      ru: {
        common: common_ru
      },
    },
    fallbackLng: 'az',
    supportedLngs: ['az', 'ru'],
  });
  return (
    <I18nextProvider i18n={i18next}>       
      <MainProvider>
         <AppRoute />
      </MainProvider>        
    </I18nextProvider>
  );
}

export default App;
