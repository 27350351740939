import React from 'react';
import NavigationMenu from '../global-components/NavigationMenu';
import Hero from './Hero';

const Header = ({data,t}) => {

    return (
        <header>
            <NavigationMenu />
            <Hero data={data} t={t}/>
        </header>
    );
}

export default Header;
