import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../constans';
import { useTranslation } from "react-i18next";
import Layouts from '../components/global-components/Layouts';
import HeaderV3 from '../components/section-components/Header-v3';
import Footer from '../components/global-components/Footer';
import Connect from '../components/section-components/Connect';
import useMain from '../providers/MainProvider';
import Service from '../components/section-components/Service';

const BlogList = () => {
    const { t } = useTranslation('common');
    const [news, setNews] = useState([])
    const [text, setText] = useState("")
    const [pageCount, setPageCount] = useState()
    const [selectedPage, setSelectedPage] = useState(1)
    const [contentLoading,setContentLoading] = useState(true)
    const [loadMoreLoading,setLoadMoreLoading] = useState(false)
    const {lng} = useMain()


    useEffect(() => {
        setContentLoading(true)
        axios.get(`${baseUrl}api/BlogPages/site?lng=${lng}&limit=6`).then((res) => {   
            setNews(res?.data?.values)           
            setPageCount(res?.data?.pageCount)
            setContentLoading(false)
        })
    }, [lng])

    useEffect(() => {
        if (selectedPage != 1) {
            setLoadMoreLoading(true)
            axios.get(`${baseUrl}api/BlogPages/site?lng=az&page=${selectedPage}&limit=6`).then((res) => {
                setNews([...news, ...res.data.values])
                setLoadMoreLoading(false)
            })
        }
    }, [selectedPage])

    return (
        <>
            <Layouts pageTitle={t("News.Title")}>
                <HeaderV3 title={t("News.Title")} pageName={t("News.PageName")} />
                {news && 
                <Service                 
                 pageCount={pageCount}
                 contentLoading={contentLoading}
                 loadMoreLoading={loadMoreLoading}
                 setSelectedPage={setSelectedPage}
                 selectedPage={selectedPage}
                 news={news}   
                 t={t}             
                />
                }              
                <Connect />
                <Footer />
            </Layouts>
        </>
    );
};

export default BlogList;