import { React } from 'react';
import { Helmet } from 'react-helmet';

const Layouts = (props) =>{
    return(
        <>
            <Helmet>
                <title>{props.pageTitle || "Azeraudit Konsaltinq"}</title>
            </Helmet>
            <div style={props.style}>{props.children}</div>
        </>
    )
}
export default Layouts;