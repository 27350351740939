import axios from 'axios';
import { find, includes, isArray, map, noop, replace, upperCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import useWindowDimensions from '../../customHooks/useWidth';
import useMain from '../../providers/MainProvider';
import Phone from '../Phone/Phone';

const NavigationMenuV2 = () => {
    const [menuToggle, setMenuToggle] = useState(false);
    const [mobileMenuToggle, setMobileMenuToggle] = useState(false);
    const { t,i18n } = useTranslation('common');
    const {headers, info, lng, handleChangeLng} = useMain()
    const [pageUrl, setPageUrl] = useState('')
    const [mouseEnteredSub,setMouseEnteredSub] = useState()
    const {width} = useWindowDimensions()

    const { lng:lang } = useParams();
    let history = useHistory();

    useEffect(()=>{
      const  pathname = history.location.pathname;
      const def = pathname.substring(0, 3) == "/ru" ? false: true;
      const path = def ? pathname: pathname.substring(3);

      if(lang != undefined && lang != "ru")  history.push('/notfound')

      if(lang == undefined && lng != 'az') history.push( "/ru" + path)
      if(lang == "ru" && lng == 'az') history.push(path)      

    },[lang, lng])


    useEffect(() => {
        setPageUrl(window?.location?.pathname)
    }, [window?.location?.pathname])

    const isPageSelected = (itemRoute,isDynamic) => {
        if (itemRoute == pageUrl) {
            return true
        }
        if(isDynamic && includes(pageUrl,itemRoute)){
            return true
        }
        if(isArray(itemRoute)){
            if(find(itemRoute,(url)=>url==pageUrl)){
                return true
            }
            else return false
        }
        return false
    }

    return (
        <>
            {/* <!-- Start menubar area --> */}
            <section className="menubar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="navbar p-0">
                                <Link className="navbar-brand p-0" to="/">
                                    <img src={info.logo2} style={{ height: "45px" , width:"145px"}} alt="Logo" />
                                </Link>
                                <div className={`header-menu ml-auto position-static ${menuToggle === true ? 'menuToggle' : ''}`}>
                                    <Phone tel={info?.phonenumber}/>
                                    <div id="menu-button" onClick={() => { setMobileMenuToggle(!mobileMenuToggle) }}><i className="fas fa-bars"></i></div>
                                    <ul className={`menu ${mobileMenuToggle === true ? 'open' : ''}`}>
                                        <li className={isPageSelected('/') && "active"}>
                                            <Link to="/">{t("Header.Home")}</Link>
                                        </li>
                                        <li className={isPageSelected(['/haqqimizda/tariximiz','/haqqimizda/layiheler']) && "active"}><Link>{t("Header.About")}</Link>
                                            <ul>
                                                <li><Link to="/haqqimizda/tariximiz">{t("Header.OurHistory")}</Link></li>
                                                <li><Link to="/haqqimizda/emekdaslar">{t("Header.OurEmployees")}</Link> </li>
                                                <li ><Link to="/haqqimizda/layiheler">{t("Header.Project")}</Link></li>
                                                <li ><Link to="/blog">{t("Header.News")}</Link></li>
                                            </ul>
                                        </li>                                       
                                        {map(headers, (main, index1) => 
                                            <li key={index1} className={isPageSelected(`/${main?.root}`,true) ? "active" :""}>
                                                {main?.root && <Link to={'/' + main?.root}> {t(main?.name)}</Link>}
                                                {!main?.root && <Link> {t(main?.name)}</Link>}
                                                <ul>
                                                    {map(main?.items, (item, index2) => item.root !== 'xidmetler/telimler' ?  (
                                                        <li className={`${isPageSelected(`/${item?.root}`) && "active"} ${mouseEnteredSub==index2 && width>991 && 'SelectedSub'}`} onMouseEnter={(()=>{width>991 ? setMouseEnteredSub(index2):noop()})} key={index2}>
                                                            <Link to={'/' + item?.root}>{item?.name}</Link>
                                                            <ul className={width>991 && 'SubSubNav'}>
                                                                {map(item?.items, (sub, index3) => (
                                                                    <li key={index3}><Link to={'/' + sub?.root}>{sub?.name}</Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </li>
                                                    ): null)}
                                                </ul>
                                            </li>
                                        )}  
                                        <li className={isPageSelected('/telimler') && "active"}><Link>{t("Header.Trainings")}</Link>
                                            <ul>
                                                {map(find(find(headers, h => h.root === 'xidmetler')?.items, item => item.root === 'xidmetler/telimler')?.items, (item, index2) => (
                                                    <li key={index2}><Link to={ replace(item.root, 'xidmetler','' )}>{item?.name}</Link></li>))}
                                                <li><Link to={'/foto-qalereya'}>{t("Header.Student")}</Link></li>
                                            </ul>
                                        </li> 
                                        {/* <li className={isPageSelected('/student') && "active"}>
                                            <Link to="/student">{t("Header.Student")}</Link>
                                        </li> */}
                                        <li className = "lang"><Link>{upperCase(i18n.resolvedLanguage)}</Link>
                                            <ul>                                               
                                               {lng!="az" &&  <li onClick={()=>handleChangeLng("az")}><Link>AZ</Link></li>}
                                               {lng!="ru" &&  <li onClick={()=>handleChangeLng("ru")}><Link>RU</Link></li>}                                          
                                            </ul>
                                        </li>  
                                    </ul>
                                </div>
                                <div className="right-part menu-toggle-button d-none d-lg-block ">
                                    <button onClick={() => { setMenuToggle(!menuToggle) }}><i className={`fas fa-${menuToggle === true ? 'times' : 'bars'}`}></i></button>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End menubar area --> */}
        </>
    );
}

export default NavigationMenuV2;
