import React, { useEffect, useState } from 'react';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import Connect from '../components/section-components/Connect';
import HeaderV3 from '../components/section-components/Header-v3';
import TeamDetailsInner from '../components/team-components/Team-details';

import axios from 'axios';
import { baseUrl } from '../constans';
import { useTranslation } from "react-i18next";
import useMain from '../providers/MainProvider';
import { useHistory, useParams } from 'react-router-dom';


const TeamDetails = (props) => {
    const [employeeData, setEmployeeData] = useState({})
    const {lng} = useMain()
    let history = useHistory();
    const { t } = useTranslation('common');
    const { id } = useParams(); 

    useEffect(() => {
        window.scrollTo(0, 0);
        if (id) {
            console.log(id)
            axios.get(`${baseUrl}api/Employees/Site/${id}?lng=${lng}`).then((res) => {
                setEmployeeData({ ...res.data })
            }).catch((err)=>{
                history.push('/notfound')
            })
        }
    }, [id,lng])

    return (
        <>
            <Layouts pageTitle={employeeData?.name +" "+employeeData?.surname}>
                <HeaderV3  title={employeeData?.name +" "+employeeData?.surname}  pageName={employeeData?.name +" "+employeeData?.surname} pageName1={t("TeamMember.PageName")} root1={"/haqqimizda/emekdaslar"} />
                {employeeData && <TeamDetailsInner employeeData={employeeData} t={t}/>}
                <Connect />
                <Footer />
            </Layouts>
        </>
    );
}

export default TeamDetails;
