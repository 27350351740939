import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layouts from '../components/global-components/Layouts';
import Footer from '../components/global-components/Footer';
import HeaderV3 from '../components/section-components/Header-v3';
import DynamicPage from '../../src/components/DynamicPages/Container'
import axios from 'axios';
import { baseUrl } from '../constans';
import { useHistory } from "react-router-dom";
import Connect from '../components/section-components/Connect';
import useMain from '../providers/MainProvider';


const Pg = () => {
    const { id } = useParams();
    let history = useHistory();
    const [pageData,setPageData] = useState()
    const {lng} = useMain()

    useEffect(()=>{
        axios.get(`${baseUrl}api/DynamicPages/Site/${id}?lng=${lng}`).then((res)=>{
            setPageData(res.data)
        }).catch((err)=>{
            history.push('/notfound') 
        })
    },[id])

    return (
        <Layouts pageTitle={pageData?.title}>
            <HeaderV3 title={pageData?.title} pageName={pageData?.title} />
            <DynamicPage pageData={pageData}/>
            <Connect />
            <Footer />
        </Layouts>
    );
};

export default Pg;