import React,{useEffect,useState} from 'react';
import Layouts from '../components/global-components/Layouts';
import HeaderV3 from '../components/section-components/Header-v3';
import AboutV2 from '../components/section-components/About-v2'
import Count from '../components/section-components/Count';
import Connect from '../components/section-components/Connect';
import Footer from '../components/global-components/Footer';
import ScrollTop from '../components/global-components/BackToTop';
import ServiceV2 from '../components/section-components/service-section/Service-v2';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { baseUrl } from '../constans';
import LoadingCubes from '../components/section-components/loading-cubes/LoadingCubes';
import useMain from '../providers/MainProvider';
import { Helmet } from 'react-helmet';

const AboutPage = () => {
    const [aboutData, setAbout] = useState({})
    const { t } = useTranslation('common');
    const [loading, setLoading]= useState(false);
    const {lng} = useMain()

    useEffect(() => {
        window.scrollTo(0,0);
        setLoading(true)
        axios.get(`${baseUrl}api/About/Site?lng=${lng}`).then((res) => {
            setAbout(res.data)
            setLoading(false)
        }).catch(()=>{
            setLoading(false)
        })
}, [lng])



    return loading? <LoadingCubes/>:    
     (
        <>
            <Layouts pageTitle={t("About.PageName")}>
                <HeaderV3 title={t("About.Title")} pageName={t("About.PageName")} />
                <AboutV2 text= {aboutData?.text}/>
                <Count about={aboutData} />
                <ServiceV2 services={aboutData?.services} t={t} />               
                <Connect />              
                <Footer />
                <ScrollTop />
            </Layouts>
        </>
    );
}

export default AboutPage;
