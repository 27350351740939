import { map } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import {sectionData} from './../../data/section.json'


const Partner = ({partners}) => {
    return (
        <>
            {/* <!-- start brand area --> */}
            <section className="brand p-20">
                <div className="container">
                    <div className="row">
                        {map(partners,(item, i)=>{
                            return (
                                <div className="col" style={{marginBottom:"30px"}} key={i}>
                                    <div className={`brand-logo text-center wow`} data-wow-duration="1.5s" data-wow-delay=".3s">
                                        <Link><img src={item.image} style={{borderRadius:5,width:"80px",height:"80px"}} className="img-fluid partner-image-logo" alt="Brand Logo" /></Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            {/* <!-- end brand area --> */}
        </>
    );
}

export default Partner;
