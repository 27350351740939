import React from 'react';
import styles from './styles.module.css'

const ContentLoading = () => {
    return (
        <div className={styles.LoadingContainer}>
            <div className={styles.ldsRoller}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default ContentLoading;