import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import SideBar from './SideBar';
import { split } from 'lodash';
import styles from "./styles.module.css"

const BlogDetails = ({data,t}) => {
    return (
        <>
            {/* <!-- start blog details area --> */}
            <section className="blog-detail pt-120">
        <div className="container">
            <div className="row">
                {/* <!-- start blog detail inner --> */}
                <div className="col-lg-8 order-1 order-lg-0">
                    <div className="detail-inner">
                        <img src={data?.image} className="img-fluid wow fadeInUp" alt="Single Blog" data-wow-duration="1.5s" data-wow-delay=".3s" />
                        <h3 className="wow fadeInUp row p-3 m-0" data-wow-duration="1.5s" data-wow-delay=".3s">{data?.title}<span className={styles.time}><i className="fas fa-calendar-alt"></i> {split(data?.createdDate,"T")[0]}</span> </h3>
                        <div className="blog-meta media align-items-center wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">                          
                            <ul className="meta-list media">
                                <li className="d-flex align-items-center">                                   
                                </li>                               
                            </ul>
                        </div>
                        <p className='ql-editor' dangerouslySetInnerHTML={{__html: data?.description}}/> 
                        <div className="share-blog media wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                            <span>share:</span>
                            <div className="media-body">
                                <ul className="social d-flex">
                                    <li><Link to=""><i className="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to=""><i className="fab fa-twitter"></i></Link></li>
                                    <li><Link to=""><i className="fab fa-instagram"></i></Link></li>
                                    <li><Link to=""><i className="fab fa-linkedin-in"></i></Link></li>
                                </ul>
                            </div>
                        </div>   
                    </div>
                </div>
                {/* <!-- end blog detail inner --> */}

                {/* <!-- start blog sidebar area --> */}
                <div className="col-lg-4 order-0 order-lg-1">
                   <SideBar data={data?.pages} t={t} />
                </div>
                {/* <!-- end blog sidebar area --> */}
            </div>
        </div>
    </section>
            {/* <!-- end blog details area --> */}

        </>
    );
}

export default BlogDetails;
