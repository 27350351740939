import React from 'react';
import styles from './styles.module.css'

const LoadingDots = () => {
    return (     
        <div className={styles.LdsEllipsis}>
           <div></div><div></div><div></div><div></div>
        </div>         
    );
};

export default LoadingDots;