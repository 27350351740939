import React, { useEffect, useState } from 'react';
import Layouts from '../components/global-components/Layouts';
import HeaderV3 from '../components/section-components/Header-v3';
import Footer from '../components/global-components/Footer';
import ScrollTop from '../components/global-components/BackToTop';
import ServiceV2 from '../components/section-components/service-section/Service-v2';
import axios from 'axios';
import { baseUrl } from '../constans';
import { useTranslation } from "react-i18next";
import LoadingCubes from '../components/section-components/loading-cubes/LoadingCubes';
import Connect from '../components/section-components/Connect';
import useMain from '../providers/MainProvider';
const ServicePage = () => {
    const [services, setServices] = useState([])
    const { t } = useTranslation('common');
    const [loading, setLoading]= useState(false);
    const {lng} = useMain()

    useEffect(() => {
        setLoading(true)
        axios.get(`${baseUrl}api/Services/Site?lng=${lng}`).then((res) => {
            setServices(res.data.values)
            setLoading(false)
        }).catch(()=>{
            setLoading(false)
        })
        window.scrollTo(0, 0);
    }, [lng])

    return loading? <LoadingCubes/>:    
    (
        <>
            <Layouts pageTitle={t("Service.Title")}>
                <HeaderV3 title={t("Service.Title")} pageName={t("Service.PageName")} />
                <ServiceV2 t={t} services={services}/>
                <Connect />
                <Footer />
                <ScrollTop />
            </Layouts>
        </>
    );
}

export default ServicePage;
