import React from 'react';
import SectionTitle from '../global-components/SectionTitle';
import {Link} from 'react-router-dom'
import { map, split } from 'lodash';

const Project = ({projects,t, description}) => {
    return (
        <div>
                {/* <!-- start project area --> */}
                <section className="project p-80 bg-blue">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                            <SectionTitle title={t("Home.ProjectTitle")} titleContent={description}/>
                            </div>
                            <div className="col-lg-12 row">
                                {map(projects,(item, i)=>{
                                    return i < 6 && (
                                        <div key={i} className="col-lg-4 col-md-6 mb-4">
                                        <div className={`item wow`} data-wow-duration="1.5s" data-wow-delay=".4s">
                                            <img src={item?.image} className="img-fluid" alt="Project" />
                                            <div className="action">
                                            <span>{item?.name}</span><br/>
                                            {!item?.ended ? <span>{t("Project.StartDate")} : {split(item?.startDate,"T")[0]}</span> :
                                            <span>{split(item?.startDate,"T")[0]} - {split(item?.endDate,"T")[0]}</span>}                                                                                       
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- end project area --> */}
        </div>
    );
}

export default Project;
