import React, { useEffect, useState } from 'react';
import styles from './styles.module.css'
import { find, map, maxBy, min, minBy, reduce } from 'lodash';
import Checkbox from '@mui/material/Checkbox';
import { CalculatorSlider, SliderMaktLabelComponent, SliderThumbComponent } from './CalculatorSlider';
import Popup from '../popupmodule/popup'
import { CalculatorForm } from './CalculatorForm';
import "./style.css";


const CalculatorInputs = ({ inputs, t, name }) => {
    const [oneTimePayment, setOneTimePayment] = useState(0)
    const [montlyPayment, setMothlyPayment] = useState(0)
    const [showPopup, setShowPopup] = useState(false)
    const [oneTimeRadio, setOneTimeRadio] = useState({})
    const [montlyRadio, setMontlyRadio] = useState({})

    const [selected, setSelected] = useState([])

    useEffect(() => {
        setSelected(map(inputs, input => {
            if (input?.inputType == "radio") input.selected = minBy(input?.options, "value")
            return input;
        }))
    }, [inputs])

    const handleTotal = (checked, value, input, option) => {
        handleInputCheckbox(checked, input, option)
        if (checked) {
            setOneTimePayment((prev) => prev + value)
        }
        else {
            setOneTimePayment((prev) => prev - value)
        }
    }

    const handleTotalRadio = (id, value, input, option) => {
        handleInputRadio(input, option)
        const updated = { ...oneTimeRadio }
        updated[id] = value
        setOneTimePayment((prev) => prev - (oneTimeRadio[id] ? oneTimeRadio[id] : 0) + updated[id])
        setOneTimeRadio({ ...updated })
    }

    const handleMontlyRadio = (id, value, input, option) => {
        handleInputRadio(input, option)
        const updated = { ...montlyRadio }
        updated[id] = value
        setMothlyPayment((prev) => prev - (montlyRadio[id] ? montlyRadio[id] : 0) + updated[id])
        setMontlyRadio({ ...updated })
    }

    const handleMontlyCheckbox = (checked, value, input, option) => {
        handleInputCheckbox(checked, input, option)
        if (checked) {
            setMothlyPayment((prev) => prev + value)
        }
        else {
            setMothlyPayment((prev) => prev - value)
        }
    }

    const handleInputCheckbox = (checked, input, option) => {
        const updated = { ...selected }
        const selectedOption = find(find(updated, s => s.id == input.id).options, o => o.id == option.id);
        selectedOption.checked = checked;
        setSelected({ ...updated })
    }

    const handleInputRadio = (input, option) => {
        const updated = { ...selected }
        const selectedInput = find(updated, s => s.id == input.id);
        selectedInput.selected = option;
        setSelected({ ...updated })
    }

    useEffect(() => {
        let defaultMontly = 0
        let updated = { ...montlyRadio }
        map(inputs, (input) => {
            if (input.inputType === 'radio') {
                updated[input.id] = minBy(input?.options, "value").value
                defaultMontly += minBy(input?.options, "value").value
            }
        })
        setMontlyRadio({ ...updated })
        setMothlyPayment(defaultMontly)
    }, [inputs])
    return (
        <div className={styles.PageContainer}>
            <div className={styles.InputContainer}>
                {map(inputs, (input, index) => (
                    input?.inputType == "checkbox" ?
                        <div key={index} className={styles.Input}>

                            <span>{input?.name}</span>

                            <div className={styles.InputParameters}>
                                {
                                    map(input?.options, (option, opti) => (
                                        <div key={opti}>
                                            <Checkbox
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                onChange={(e) => input.isMonthly ? handleMontlyCheckbox(e.target.checked, option.value, input, option) : handleTotal(e.target.checked, option.value, input, option)}
                                            />
                                            <span>{option?.name}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div> :
                        <div key={index} className={styles.Input}>

                            <p>{input.name}</p>

                            <div className={styles.SliderParameters}>
                                <div>
                                    <CalculatorSlider
                                        className={"CalculatorSlider"}
                                        aria-label="Restricted values"
                                        valueLabelFormat={(value)=>{                                            
                                            return input.options[value].value;
                                        }}
                                        components={{
                                            Thumb: SliderThumbComponent,
                                            MarkLabel: SliderMaktLabelComponent
                                        }}
                                        defaultValue={0}
                                        onChange={(event) => input.isMonthly ?
                                            handleMontlyRadio(input.id, input.options[event.target.value].value, input, input.options[event.target.value]) :
                                            handleTotalRadio(input.id, input.options[event.target.value].value, input, input.options[event.target.value])}
                                        step={null}
                                        valueLabelDisplay="auto"
                                        min={0}
                                        max={input?.options?.length - 1}
                                        marks={map(input?.options, (option, i) => ({ value: i, label: option?.name }))}
                                    />
                                </div>
                            </div>
                        </div>
                ))}
            </div>
            <div className={styles.TotalConlainer}>
                <div><span>{t("Calculator.OrderSummary")}</span></div>
                <div>
                    <span>{t("Calculator.OneTimePayment")}</span>
                    <span>{oneTimePayment} AZN</span>
                </div>
                <div>
                    <span>{t("Calculator.Monthly")}</span>
                    <span>{montlyPayment} AZN</span>
                </div>
                <div><button type="button" onClick={() => setShowPopup(true)} className="btn-style">{t("Calculator.Send")}</button></div>
            </div>
            {showPopup ?
                <Popup closeModule={() => setShowPopup(false)}>
                    <div className={styles.PopupContent}>
                        <CalculatorForm calculatorName={name} setShowPopup={setShowPopup} inputs={selected} t={t} oneTimePayment={oneTimePayment} montlyPayment={montlyPayment} />
                    </div>
                </Popup> : null
            }
        </div>
    );
};

export default CalculatorInputs;