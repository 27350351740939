import { find, forEach, keys, reduce } from 'lodash';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.css'
import axios from "axios";
import { baseUrl } from '../../constans';

export const CalculatorForm = ({ inputs, t,setShowPopup, oneTimePayment, montlyPayment, calculatorName}) => {

    const [data,setData] = useState(
        { 
            name:"",
            surname:"", 
            phonenumber:"",
            companyName:"",
            calculatorName: calculatorName,
            oneTimePayment: oneTimePayment,
            montlyPayment: montlyPayment,
            text: reduce(
                inputs,
                (acc, input) => {
                  if(input?.selected) acc = acc +`${input?.name}<br/>-- ${input?.selected.name} ${input?.selected.value} AZN<br/><br/>`; 
                  else if(find(input.options, o => o.checked)){
                    acc  = acc + `${input?.name} <br/>`; 
                    forEach(input.options, o=>{
                        if(o.checked)  acc  = acc + `-- ${o?.name} ${o?.value} AZN<br/>`; 
                    })
                    acc  = acc + `<br/>`;                  
                  } 
                  return acc;
                },
                "",
              )       
        }
    )

    const [loading, setLoading]= useState(false)

    const [error,setInputError] = useState(
        { 
            name:false,
            surname:false,
            phonenumber:false,
            companyName:false,            
        }
    )

    const [success, setSuccess]= useState(false)
    
    const handleChange = (input, value) => {
        const updated = { ...data }
        updated[input] = value;
        setData({ ...updated })
        handleValidate(input,value)
        console.log(value)
    }

    const handleValidate = (input, value) => {
        const updated = { ...error }
       
        let errorValue;

        if(input === 'phonenumber') {
            errorValue = value?.length < 17;
        }
        else {
            errorValue = value?.length < 1; 
        }

        updated[input] = errorValue;

        setInputError({...updated})        
        
        return errorValue;
    }

    const checkErrors = () => {
        const updated = { ...error }
        let errors = false;
        forEach(keys(data),key=>{
            let errorValue;
            if(key === 'phonenumber') {
                errorValue = data[key]?.length < 17;
            }
            else {
                errorValue = data[key]?.length < 1; 
            }
            updated[key] = errorValue;

            if(errorValue) errors = errorValue;
        })        
        setInputError({...updated})    
        return errors;
    }

    const handleSubmit = () => {
        console.log('submit',data)
        if(!checkErrors()){
            setLoading(true)
            axios({
                method: "POST", 
                url:`${baseUrl}api/email/calculator`, 
                data:  data
            })
            .then(res=>{
                if(res.status === 200){
                    setSuccess(true)
                }
                setLoading(false)
                setTimeout(() => {
                    setShowPopup(false)
                  }, 1000)

            })
            .catch((e)=>{
                setLoading(false)
                setTimeout(() => {
                    setShowPopup(false)
                  }, 1000)
            })
        }      
    }

    const normalizeInput = (value, previousValue) => {
        if (!value) return previousValue;
        let currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        console.log(value,currentValue,previousValue)

        if(currentValue.slice(0, 3) != '994') currentValue = '994'+ currentValue;

        if(value.length > 17) return previousValue;
        if(value.length < 6 && value.length < previousValue.length) return "";

        if (cvLength < 6) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        if (cvLength < 9) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 5)} ${currentValue.slice(5, 8)}`;
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 5)} ${currentValue.slice(5, 8)} ${currentValue.slice(8)}`;       
      };

    return (    
        <form className={styles.CalculatorForm}>
            {console.log("form",error)}
            <input type="text" className={error.name? styles.error:""} value={data.name} 
            onChange={(e) =>handleChange('name',e.target.value)} placeholder={t("Calculator.Name")} />

            <input type="text" className={error.surname? styles.error:""} value={data.surname} 
            onChange={(e) =>handleChange('surname',e.target.value)} placeholder={t("Calculator.Surname")} />

            <input type="text" className={error.companyName? styles.error:""} value={data.companyName} 
            onChange={(e) =>handleChange('companyName',e.target.value)} placeholder={t("Calculator.CompanyName")} />

            <input type="text" className={error.phonenumber? styles.error:""} value={data.phonenumber} 
            onChange={(e) =>handleChange('phonenumber',normalizeInput(e.target.value,data.phonenumber))} placeholder={t("Calculator.PhoneNumber")} />

            <button type="button" onClick={handleSubmit} disabled={loading} className="btn-style " >
                <span>{t("Calculator.Send")}</span>
            </button>

            {success && <span>{t("Calculator.Success")}</span>}
            {loading && <span>{t("Calculator.Loading")}</span>}
            
        </form>
    );
}