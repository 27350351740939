import React from 'react';
import style from './index.module.css'

const popup = (props) => {
    return (
        <div className={style.Popup}>
            <div className={style.ContentContainer}>
                <div className={`${style.Content} ${props.spinner && style.SpinnerContent}`}>
                    <i className={style.ModalCloseBtn} ><img onClick={props.closeModule} src="/assets/icon/modalCloseBtn.svg" alt=""/></i>
                    {props.children}
                </div>
                
            </div>

            <div onClick={props.closeModule} className={style.moduleBackground}>
                
            </div>
        </div>
    );
};

export default popup;