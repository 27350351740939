import { map, split } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useMain from '../../providers/MainProvider';

const Footer =()=>{
    const { footerServices,
        footerPages,
        footerTest,
        address,
        info} = useMain()
    const { t } = useTranslation('common');
    return (
        <>
                {/* <!-- start footer area --> */}
                <footer  style={{background:`url(${split(info.footerImage, " ")[0]})`, backgroundSize: "cover"}}>
                    <div className="overlay">
                        {/* <!-- start footer-top area --> */}
                        <section className="footer-top">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-3 col-md-6">
                                        <div className="title">
                                            <h4>{t('Footer.AboutTitle')}</h4>
                                        </div>
                                        <p>
                                        <p className='ql-editor' dangerouslySetInnerHTML={{__html: footerTest}}/>
                                        </p>
                                        <div className="more">
                                            <Link to="/haqqimizda/tariximiz">{t('Footer.ReadMore')}<i className="flaticon-chevron-1"></i></Link>
                                        </div>
                                        <div className="social">
                                            <ul className="d-flex">
                                            {info.facebook && <li><a target="_blank" rel="noopener noreferrer" href={info.facebook}>{console.log("scdscs "+info.facebook)}<i className={`fab fa-facebook`}></i></a></li> }                                               
                                            {info.instagram && <li><a target="_blank" rel="noopener noreferrer" href={info.instagram}><i className={`fab fa-instagram`}></i></a></li> }                                               
                                            {info.twitter && <li><a target="_blank" rel="noopener noreferrer" href={info.twitter}><i className={`fab fa-youtube`}></i></a></li>}                                                
                                            {info.linkedin && <li><a target="_blank" rel="noopener noreferrer" href={info.linkedin}><i className={`fab fa-linkedin`}></i></a></li>}       
                                            {info?.phonenumber && <li><a href={"https://wa.me/"+info?.phonenumber.replace("+","").trim()}><i className={`fab fa-whatsapp`}></i></a></li> }                                                                                        
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 mt-sm-30">
                                        <div className="title">
                                            <h4>{t('Footer.Information')}</h4>
                                        </div>
                                        <div className="info">
                                            <ul>                                               
                                                <li ><Link to="/haqqimizda/tariximiz">{t('Footer.About')}</Link></li>                                           
                                                <li ><Link to="/haqqimizda/emekdaslar">{t('Footer.Team')}</Link></li>                                           
                                                <li ><Link to="/haqqimizda/layiheler">{t('Footer.Project')}</Link></li>                                           
                                                <li ><Link to="/foto-qalereya">{t('Footer.PhotoGallery')}</Link></li>                                           
                                                {/* <li ><Link to="/student">{t('Footer.Student')}</Link></li>                                            */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-6 mt-md-30">
                                        <div className="title">
                                            <h4>{t('Footer.BestServices')}</h4>
                                        </div>
                                        <div className="service-list">
                                            <ul>
                                            {map(footerServices,(item, i)=>{
                                                    return i < 5 && <li key={i}><Link to={`/${item.root}`}>{item.name}</Link></li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>                                   
                                    <div className="col-xl-3 col-lg-3 col-md-6 mt-md-30">
                                        <div className="title">
                                            <h4>{t('Footer.News')}</h4>
                                        </div>
                                        <div className="news">
                                        {map(footerPages,(item, i)=>{                                                
                                                    return i < 6 && <div className="news-item" key={i}>
                                                    <img src={item.image} className="img-fluid" alt="News" />
                                                    <div className="overlay text-center">
                                                        <Link to={`/blog/${item.pageId}`}><i className="fas fa-link"></i></Link>
                                                    </div>
                                                </div>
                                                })}                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <!-- end footer-top area --> */}

                        {/* <!-- start footer-bottom area --> */}
                        <section className="footer-bottom">
                            <div className="container">
                                <div className="footer-bottom-content">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-5">
                                            <div className="copyright">
                                                <p>
                                                    Copyright &copy; 2023. All Rights
                                                    Reserved
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-7 mt-sm-20">
                                            <div className="footer-menu copyright">
                                                <p className="footer-menu copyright d-flex justify-content-end align-items-center" >
                                                <i className='fas fa-phone-alt mr-2'></i> {info?.phonenumber} <i className='far fa-map ml-4 mr-2'></i> {address} 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <!-- end footer-bottom area --> */}
                    </div>
                </footer>
                {/* <!-- end footer area --> */}
        </>
        )
}
export default Footer;