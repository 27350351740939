import React from 'react';
import styles from './styles.module.css'
import map from "lodash/map"

const GaleryHeader = ({ categories, selectedCategory, setSelectedCategory }) => {
    return (
        <div className={styles.CategoryConrainer}>
            {map(categories, (item) => (
                <span onClick={() => setSelectedCategory(item.id)} className={`${styles.categoryP} ${selectedCategory==item.id && styles.SelectedCategory}`}>{item.name}</span>
            ))}
        </div>
    );
};

export default GaleryHeader;