import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ErrorInner = () => {
    const { t } = useTranslation('common');
   
    return (
        <>
                {/* <!-- start header area --> */}
    <header className="index2 about-page error-page">
        {/* <!-- start banner area --> */}
        <section className="banner" style={{background: "rgb(2,0,36)",
            background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)"}}>
            <div className="overlay" style={{ height:"100vh" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="banner-text text-center wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                                <h1>404</h1>
                                <h3>{t("NotFound.Title")}</h3>
                                <p></p>
                                <Link to="/" className="btn-style"><span>{t("NotFound.GoToHome")}</span></Link>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </section>
        {/* <!-- end banner area --> */}
    </header>
    {/* <!-- end header area --> */}
</>
    );
}

export default ErrorInner;
