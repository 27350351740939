import React from 'react';
import './style.css'


const Spinner = () => {
    return (
      <svg className="spinner" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16"><path className="path" fill="white" d="M2.501 8a5.5 5.5 0 1 1 5.5 5.5A.75.75 0 0 0 8 15a7 7 0 1 0-7-7a.75.75 0 0 0 1.501 0Z"/></svg>
    );
}

export default Spinner;