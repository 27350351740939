import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../global-components/SectionTitle';
import {sectionData} from './../../data/section.json'
import { split } from 'lodash';

const Offer = ({data,t}) => {
    
    return (
        <>
              {/* <!-- start offer area --> */}
    <section className="offer pt-80 pb-80">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <SectionTitle title={t("Home.About")}/>
                </div>
                <div className="col-lg-12">
                    <div className="row justify-content-center">                      
                        <div className="col-lg-4 col-md-6">
                            <div className={`item wow`} style={{background:`url(${split(data?.aboutImage1, " ")[0]})`, backgroundSize: "100% 350px", backgroundRepeat: "no-repeat"}} data-wow-duration="1.5s">                                                                          
                            <div className='text'>
                                <h3>{data?.aboutTitle1}</h3>
                                <p>{data?.aboutDescription1}</p>      
                            </div>                           
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className={`item wow`} style={{background:`url(${split(data?.aboutImage2, " ")[0]})`, backgroundSize: "100% 350px", backgroundRepeat: "no-repeat"}} data-wow-duration="1.5s">
                            <div className='text'>
                                <h3>{data?.aboutTitle2}</h3>
                                <p>{data?.aboutDescription2}</p>  
                            </div>                               
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className={`item wow`} style={{background:`url(${split(data?.aboutImage3, " ")[0]})`, backgroundSize: "100% 350px", backgroundRepeat: "no-repeat"}} data-wow-duration="1.5s">
                                <div className='text'>
                                    <h3>{data?.aboutTitle3}</h3>
                                    <p>{data?.aboutDescription3}</p>   
                                </div>                             
                            </div>
                        </div>
                              
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- end offer area -->   */}
        </>
    );
}

export default Offer;
