import React from 'react';
import styles from './styles.module.css'
import map from 'lodash/map'
import { Link } from 'react-router-dom';
import { find } from 'lodash';

const ServiceDetailsInner = ({t,serviceData,openedTab,id}) => {

    const data = find(serviceData?.serviceItems, s=> s.root === openedTab)
   
    return (
        <>
            {/* <!-- start service details area --> */}
            <section className="service-detail pt-120">
                <div className="container">
                    <div className="row">
                        {/* <!-- start main content area --> */}
                        <div className="col-lg-8 order-1 order-lg-0">
                            <div className={`details-inner ${styles.marginBottom}`}>
                                <img src={serviceData.largeImage} className="img-fluid wow fadeInUp" alt="Service" data-wow-duration="1.5s" data-wow-delay=".3s" />
                                <h3 className="wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">{data?.name}</h3>
                                <div className="details-bottom wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                                    <p className='ql-editor' dangerouslySetInnerHTML={{__html: data?.text}}/>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end main content area --> */}

                        {/* <!-- start detail sidebar area --> */}
                        <div className={`col-lg-4 col-md-8 offset-md-2 col-sm-12 order-0 order-lg-1 offset-lg-0 ${styles.marginBottom}`}>
                            <div className="single-item wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                                <div className="item-title all">
                                    <h4>{t('ServiceDetail.AllServices')}</h4>
                                </div>
                                <ul>
                                    {map(serviceData.serviceItems,(item,i)=>{
                                        return <li className={`${styles.tabs} ${openedTab === item?.root && styles.selectedTab}`} key={i}><Link to={`/xidmetler/${id}/${item?.root}`}>
                                        <p> {item?.name}</p></Link></li>
                                    })}
                                </ul>
                            </div>
                        </div>
                        {/* <!-- end detail sidebar area --> */}
                    </div>
                </div>
            </section>
            {/* <!-- end service details area --> */}

        </>
    );
}

export default ServiceDetailsInner;
