import React from 'react';
import SectionTitle from '../../global-components/SectionTitle';
import { Link } from 'react-router-dom';
import map from 'lodash/map';
import split from 'lodash/split'
import styles from './styles.module.css'

const Training = ({ t, data, home }) => {    
    return (
        <>
            {/* <!-- start offer area --> */}
            <section className="offer pt-80 index2 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                        <SectionTitle title={t('Header.Trainings')} />
                        </div>
                        <div className="col-lg-12">
                            <div className="row justify-content-center">
                                {map(data, (item, i) => {
                                    return (!home || i < 6) && (
                                        <Link className="col-lg-4 col-md-6"  to={`/telimler/${item?.root}`} key={i}>                                        
                                        <div >
                                            <div className={`item wow testtest  ${item.animation}`} style={{ backgroundImage: `url(${ split(item.smallImage, " ")[0]})` }} data-img={item.smallImage} data-wow-duration="1.5s" data-wow-delay=".3s">
                                                <div className="overlay">
                                                    <div className={`${styles.icon} icon`}>
                                                        <img className={styles.iconImage}  src={item.icon}/>
                                                    </div>
                                                    <h3 className={styles.text}>{item.name}</h3>
                                                    <ul className={styles.detail}>
                                                        
                                                    </ul>
                                                                                                       
                                                    <Link to={`/telimler/${data?.root}`} className="btn-style"><span>{t('Service.ReadMore')}</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- end offer area --> */}
        </>
    );
}

export default Training;
