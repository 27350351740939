import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../constans';
import { useTranslation } from "react-i18next";
import Layouts from '../components/global-components/Layouts';
import HeaderV3 from '../components/section-components/Header-v3';
import Footer from '../components/global-components/Footer';
import GaleryContainer from '../components/galery-component/GaleryContainer';
import Connect from '../components/section-components/Connect';
import useMain from '../providers/MainProvider';

const PhotoGalery = () => {
    const [selectedCategory, setSelectedCategory] = useState()
    const { t } = useTranslation('common');
    const [imageData, setImageData] = useState({})
    const [categories, setCategories] = useState([])
    const [pageCount, setPageCount] = useState()
    const [selectedPage, setSelectedPage] = useState(1)
    const [contentLoading,setContentLoading] = useState(true)
    const [loadMoreLoading,setLoadMoreLoading] = useState(false)
    const {lng} = useMain()

    useEffect(() => {
        setContentLoading(true)
        axios.get(`${baseUrl}api/GalleryCategories/Site?lng=${lng}`).then((res) => {
            setCategories(res.data.categories)
            let field = `${res?.data?.categories[0]?.id}`
            setSelectedCategory(res?.data?.categories[0]?.id)
            setImageData({
                ...imageData,
                [field]: res.data.values,
                ["page"+field]:1,
                ['pageCount'+selectedCategory]:res.data.pageCount
            })
            setPageCount(res.data.pageCount)
            setContentLoading(false)
        })
    }, [lng])

    useEffect(() => {      
        setSelectedPage(imageData["page"+selectedCategory] || 1)
        
        if (imageData && !imageData[selectedCategory]?.length > 0 && selectedCategory) {
            setContentLoading(true)
            axios.get(`${baseUrl}api/GalleryCategories/Site/${selectedCategory}/?lng=${lng}&page=${imageData["page"+selectedCategory]}&limit=6`).then((res) => {
                setImageData({
                    ...imageData,
                    [selectedCategory]: [...res.data.values],
                    ['pageCount'+selectedCategory]: res.data.pageCount
                })
                setPageCount(res.data.pageCount)
                setContentLoading(false)
            })
        }
        
    }, [selectedCategory])

    useEffect(() => {
        if (selectedCategory && selectedPage != 1) {
            setLoadMoreLoading(true)
            axios.get(`${baseUrl}api/GalleryCategories/Site/${selectedCategory}/?lng=${lng}&page=${selectedPage}&limit=6`).then((res) => {
                setImageData({
                    ...imageData,
                    [selectedCategory]: [...imageData[selectedCategory], ...res.data.values],
                    ["page"+selectedCategory]:selectedPage                    
                })
                setLoadMoreLoading(false)
            })
        }
    }, [selectedPage])

    return (
        <>
            <Layouts pageTitle={t("PhotoGallery.Title")}>
                <HeaderV3 background={""} title={t("PhotoGallery.Title")} pageName={t("PhotoGallery.PageName")} />
                {categories &&
                    <GaleryContainer
                        pageCount={imageData['pageCount'+selectedCategory]}
                        contentLoading={contentLoading}
                        loadMoreLoading={loadMoreLoading}
                        setSelectedPage={setSelectedPage}
                        selectedPage={selectedPage}
                        images={imageData[selectedCategory]}
                        categories={categories}
                        setSelectedCategory={setSelectedCategory}
                        selectedCategory={selectedCategory}          
                        t={t}             
                    />}
                     <Connect />
                <Footer />
            </Layouts>
        </>
    );
};

export default PhotoGalery;