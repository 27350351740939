import React, { useState } from 'react';
import styles from './styles.module.css'
import Carousel from 'react-grid-carousel'
import Lightbox from 'react-image-lightbox';
import { map } from 'lodash';


const Container = ({ pageData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [main, setMain] = useState(0);
    const images = pageData?.images || []
    return (
        
        <div className={styles.DynamicPageContainer}>
            {console.log("pageData",pageData)}
            {pageData && <>
                <div className={styles.Description}>
                    <img src={pageData?.mainImage} />                    
                    { <p className='ql-editor' dangerouslySetInnerHTML={{__html: pageData?.description}}/> }
                </div>
                <div className={styles.imageConitaner}>
                    <Carousel autoplay={true} cols={3} rows={1} gap={10} loop>
                        {images && map(images, (img,index) => (
                            <Carousel.Item>
                                <img onClick={() => {
                                    setMain(index);
                                    setIsOpen(true);
                                }} width="100%" src={img.image} />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
                {isOpen && <Lightbox
                    mainSrc={images[main].image}
                    nextSrc={images[(main + 1) % images.length].image}
                    prevSrc={
                        images[
                            (main + images.length - 1) % images.length
                        ].image
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setMain(
                            (main + images.length - 1) % images.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setMain((main + 1) % images.length)
                    }
                />
                }
            </>}
        </div>
    );
};

export default Container;