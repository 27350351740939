import React, { useEffect, useState } from 'react';
import { useHistory , useParams } from 'react-router-dom';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import HeaderV3 from '../components/section-components/Header-v3';
import TrainingDetailsInner from '../components/Training-components/training-details';
import axios from 'axios';
import { baseUrl } from '../constans';
import { useTranslation } from "react-i18next";
import LoadingCubes from '../components/section-components/loading-cubes/LoadingCubes';
import Connect from '../components/section-components/Connect';
import useMain from '../providers/MainProvider';

const TrainingDetails = () => {
    const [serviceData,setServiceData] = useState()
    let history = useHistory();
    const [loading, setLoading]= useState(false);
    const {lng} = useMain()

    const { id } = useParams();    
    const { t } = useTranslation('common');

    useEffect(() => {        
        window.scrollTo(0, 0);
        setLoading(true)
       
            axios.get(`${baseUrl}api/Services/Site/telimler?lng=${lng}`).then((res) => {
                setServiceData(res.data)             
                setLoading(false)
            }).catch((err)=>{
                history.push('/notfound')
            })
    }, [lng])

    return loading? <LoadingCubes/>:  (
        <>
            <Layouts pageTitle={serviceData?.name}>
                <HeaderV3 title={serviceData?.name}  pageName={serviceData?.name}  />
                {serviceData && <TrainingDetailsInner openedTab={id || serviceData?.serviceItems[0]?.root} serviceData={serviceData} t={t}/>}
                <Connect />
                <Footer />
            </Layouts>
        </>
    );
}

export default TrainingDetails;
