import React from 'react';
import { sectionData } from '../../../data/section.json'
import SectionTitle from '../../global-components/SectionTitle';
import { Link } from 'react-router-dom';
import map from 'lodash/map';
import split from 'lodash/split'
import { t } from 'i18next';
import styles from './styles.module.css'

const ServiceV2 = ({ t, services, description }) => {    
    return (
        <>
            {/* <!-- start offer area --> */}
            <section className="offer pt-80 index2 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                       {!description  ? <SectionTitle title={t('Service.SectionTitle')} />:
                       <SectionTitle title={t('Service.SectionTitle')} titleContent={description} />} 
                        </div>
                        <div className="col-lg-12">
                            <div className="row justify-content-center">
                                {map(services, (item, i) => {
                                    return i < 6 && (
                                        <Link className="col-lg-4 col-md-6"  to={`/xidmetler/${item.root}`} key={i}>                                        
                                        <div >
                                            <div className={`item wow testtest  ${item.animation}`} style={{ backgroundImage: `url(${ split(item.smallImage, " ")[0]})` }} data-img={item.smallImage} data-wow-duration="1.5s" data-wow-delay=".3s">
                                                <div className="overlay">
                                                    <div className={`${styles.icon} icon`}>
                                                        <img className={styles.iconImage}  src={item.icon}/>
                                                    </div>
                                                    
                                                    <h3>{item.name}</h3>
                                                    <ul className={styles.detail}>
                                                        {map(item.items?.slice(0, 3), (item, i) => {
                                                            return <li key={i}>{item}</li>
                                                        })}
                                                    </ul>
                                                    <Link to={`/xidmetler/${item.root}`} className="btn-style"><span>{t('Service.ReadMore')}</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- end offer area --> */}
        </>
    );
}

export default ServiceV2;
