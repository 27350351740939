import React, { useEffect, useState } from 'react';
import Footer from '../components/global-components/Footer';
import Layouts from '../components/global-components/Layouts';
import Connect from '../components/section-components/Connect';
import HeaderV3 from '../components/section-components/Header-v3';
import Team from '../components/section-components/team-section/Team';
import axios from 'axios';
import { baseUrl } from '../constans';
import { useTranslation } from 'react-i18next';
import useMain from '../providers/MainProvider';


const TeamPage = () => {
    const [employeeList, setEmployeeList] = useState([])
    const { t } = useTranslation('common');
    const {lng} = useMain()
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`${baseUrl}api/Employees/Site?lng=${lng}&limit=100`).then((res) => {
            setEmployeeList([...res.data.values])
        })
    }, [lng])


    return (
        <>
            <Layouts pageTitle={t("TeamMember.PageName")}>
                <HeaderV3 title={t("TeamMember.Title")} pageName={t("TeamMember.PageName")} />
                {employeeList && <Team t={t} employeeList={employeeList} />}
                <Connect />
                <Footer />
            </Layouts>
        </>
    );
}

export default TeamPage;
