import React from 'react';
import SectionTitle from '../global-components/SectionTitle';
import { map } from 'lodash';

const secondExample = {
    size: 20,
    color: "black",
    activeColor: "#ecc026",
    count: 5,
    value: 7.5,
    a11y: true,
    isHalf: true,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
    onChange: newValue => {
    //   console.log(`Example 2: new value is ${newValue}`);
    }
  };
const Client = ({data,t}) => {
    return (
        <div>
            {/* <!-- start client area --> */}
            <section className={`client pb-80 pt-80 ${window.location.pathname === '/about' ? "bg-blue": " "}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                        <SectionTitle title={t("Home.Info")}/>
                        </div>
                    </div>
                        <div className="row justify-content-center">
                            {
                                map(data,(item, i)=>{
                                   return i < 6 && <div className="col-md-6 col-lg-4" key={i}>
                                {/* <!-- item 1 --> */}
                                <div className={`item-wrapper swiper-slide wow mb-30`} data-wow-duration="1.5s" data-wow-delay=".4s">
                                    <div className="item text-center">
                                        <div className="client-img">
                                            <div className="border-style">
                                                <img src={item?.image} className="img-fluid" alt="Client" />
                                            </div>
                                        </div>
                                        <h3>{item?.text}</h3> 
                                    </div>
                                </div>
                            </div>
                                }) 
                            }
                        </div>
                    </div>
                </section>
                {/* <!-- end client area --> */}
        </div>
    );
}

export default Client;
