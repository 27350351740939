import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../global-components/SectionTitle";
import styles from "./styles.module.css";

const Team = ({ employeeList, description, t, isMain }) => {
  let data = employeeList;
  console.log(employeeList);
  return (
    <div>
      {/* <!-- start team area --> */}
      <section
        className={`team p-80 ${
          window.location.pathname === "/team" ? "team-page" : 3
        }`}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <SectionTitle
                title={t("TeamMember.Title")}
                titleContent={description || ""}
              />
            </div>
            <div className="col-lg-12">
              <div className="row justify-content-center">
                {data?.map((item, i) => {
                  return (
                    (!isMain || i < 9) && (
                      <div
                        className={`col-lg-4 col-md-6 wow ${styles.teamMember} fadeInRight`}
                        data-wow-duration="1.5s"
                        data-wow-delay=".4s"
                        key={i}
                      >
                        <Link to={`/haqqimizda/emekdaslar/${item.root}`}>
                          <div className="item">
                            <div className="image">
                              <img
                                src={item.image}
                                className={`img-fluid ${styles.image}`}
                                alt="Team Member"
                              />
                              <div className="overlay">
                                <ul className="d-flex justify-content-center">
                                  {item.linkFacebook && (
                                    <li>
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={item.linkFacebook}
                                      >
                                        <i className="fab fa-facebook-f"></i>
                                      </a>
                                    </li>
                                  )}
                                  {item.linkTwitter && (
                                    <li>
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={item.linkTwitter}
                                      >
                                        <i className="fab fa-twitter"></i>
                                      </a>
                                    </li>
                                  )}
                                  {item.linkInstagram && (
                                    <li>
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={item.linkInstagram}
                                      >
                                        <i className="fab fa-instagram"></i>
                                      </a>
                                    </li>
                                  )}
                                  {item.linkLinkedin && (
                                    <li>
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={item.linkLinkedin}
                                      >
                                        <i className="fab fa-linkedin-in"></i>
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div className="shape">
                              <div className="text">
                                <Link
                                  to={`/haqqimizda/emekdaslar/${item.root}`}
                                >
                                  {item.name} {item.surname}
                                </Link>
                                <span>{item.position}</span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end team area --> */}
    </div>
  );
};

export default Team;
