import React from 'react';
import Forms from '../global-components/Forms';

const ContactInner = ({data,t}) => {
    return (
        <>
            {/* <!-- start contact area --> */}
            <section className="p-120 contact-page">
                <div className="container contact">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="section-title wow fadeInRight" data-wow-duration="1.5s" data-wow-delay=".3s">
                                <h2><span>{t("Contact.InnerTitle")}</span></h2>
                            </div>
                            <Forms categories={data?.emailForms || []} t={t} />                            
                        </div>
                        <div className="col-lg-6">
                            <div className="right-part mt-md-60">
                                <div className="section-title wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".3s">
                                    <h2><span>{t("Contact.Contact")}</span></h2>
                                </div>
                                <ul>
                                     <li className="media wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".3s">
                                        <div className="icon">
                                            <i className='far fa-map'></i>
                                        </div>
                                        <div className="text">
                                            <h4>{t("Contact.Address")}</h4>
                                            <p>{data?.address}</p>
                                        </div>
                                    </li>  
                                    <li className="media wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".3s">
                                        <div className="icon">
                                            <i className='fas fa-phone-alt'></i>
                                        </div>
                                        <div className="text">
                                            <h4>{t("Contact.Phone")}</h4>
                                            <p>{data?.phonenumber}</p>
                                        </div>
                                    </li> 
                                    <li className="media wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".3s">
                                        <div className="icon">
                                            <i className='far fa-envelope'></i>
                                        </div>
                                        <div className="text">
                                            <h4>{t("Contact.Email")}</h4>
                                            <p>{data?.email}</p>
                                        </div>
                                    </li>                                  
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- end contact area --> */}

        </>
    );
}

export default ContactInner;
