import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import  map  from 'lodash/map';

const Count = ({about}) => {


    const handleCount=()=>{
        const counts = []

        if(!about) return null

        for (let index = 1; index < 5; index++) {
            counts.push({
                title:about["chartText"+index] ,
                percent:about["chartValue"+index]/about["maxChartValue"+index]*100,
                value:about["chartValue"+index],
                animation:"fadeInRight",
                animationDelay:"0.4s"
            })        
        }
        
        return counts;
    }

    return handleCount() ? (
        <div>
            {/* <!-- start count area --> */}
            <section className="count pt-90 bg-blue">
                <div className="container">
                    <div className="row">
                        {
                            map(handleCount(),(item, i)=>{
                                return (
                                    <div className="col-lg-3 col-md-6" key={i}>
                                        <div className={`count-item text-center wow ${item.animation}`} data-wow-duration="1.5s" data-wow-delay={item.animationDelay}>
                                            <div className="circle">                                                
                                                    <CircularProgressbar value={item.percent} text={`${item.value}`} />                                               
                                                <h5>{item.title}</h5>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            {/* <!-- end count area --> */}
        </div>
    ): null;
}

export default Count;
