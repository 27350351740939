import React from 'react';
import { Link } from 'react-router-dom';

const SingleMemberInfo = ({ employeeData, t }) => {
    return (
        <>
            <div className="team-inner inner-shadow wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                <img src={employeeData.image} className="img-fluid" alt="Team" />
                <div className="fig-detail text-center">
                    <h3>{`${employeeData.name} ${employeeData.surname}`}</h3>
                    <p>{employeeData.position}</p>
                    <div className="social">
                        <ul className="d-flex justify-content-center">
                            {employeeData.linkFacebook && <li>
                                <a target="_blank" rel="noopener noreferrer" href={employeeData.linkFacebook}><i className="fab fa-facebook-f"></i></a>
                                {console.log("employeeData.linkFacebook")}
                            </li>}
                            {employeeData.linkTwitter && <li>
                                <a target="_blank" rel="noopener noreferrer" href={employeeData.linkTwitter}><i className="fab fa-twitter"></i></a>
                            </li>}
                            {employeeData.linkInstagram && <li>
                                <a target="_blank" rel="noopener noreferrer" href={employeeData.linkInstagram}><i className="fab fa-instagram"></i></a>
                            </li>}
                            {employeeData.linkLinkedin && <li>
                                <a target="_blank" rel="noopener noreferrer" href={employeeData.linkLinkedin}><i className="fab fa-linkedin-in"></i></a>
                            </li>}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="team-inner inner-shadow wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                <div className="single-item mt-md-30 personal-info">
                    <div className="item-title">
                        <h4>{t("TeamMember.ProfileInfo")}</h4>
                    </div>
                    <ul>
                        {employeeData.perconalPhonenumber && <li >
                            <p style={{paddingLeft: "160px"}}><span>{t('TeamMember.Phone')}</span>{employeeData.perconalPhonenumber}</p>
                        </li>}
                        {employeeData.officePhonenumber && <li >
                            <p style={{paddingLeft: "160px"}}><span>{t("TeamMember.Office")}</span>{employeeData.officePhonenumber}</p>
                        </li>}
                        {employeeData.email && <li>
                            <p style={{paddingLeft: "160px"}}><span>{t('TeamMember.Email')}</span>{employeeData.email}</p>
                        </li>}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default SingleMemberInfo;
