import React from 'react';
import SingleMemberInfo from './SingleMember';

const TeamDetailsInner = ({employeeData,t}) => {
    return (
        <>
                {/* <!-- start team details area --> */}
    <section className="team-detail pt-120">
        <div className="container">
            <div className="row">
                <div className="col-lg-4">
                    <SingleMemberInfo t={t} employeeData={employeeData}/>
                </div>

                <div className="col-lg-8">
                    <div className="team-inner right-part">
                        <div className="single-item wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                            <div className="item-title">
                                <h4>{t('TeamMember.About')}</h4>
                            </div>
                            <p className='ql-editor' dangerouslySetInnerHTML={{__html: employeeData.longDescription}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- end team details area --> */}

        </>
    );
}

export default TeamDetailsInner;
