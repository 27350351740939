import React,{useState} from 'react';
import axios from "axios";
import { find, map } from 'lodash';
import { baseUrl } from '../../constans';
import Spinner from '../Spinner/Spinner';
import './style.css';

const Forms = ({categories,t}) => {
    const [inputName, setInputName]= useState("");
    const [inputSurname, setInputSurname]= useState("");
    const [inputEmail, setInputEmail]= useState("");
    const [inputPhone, setInputPhone]= useState("");
    const [inputCategory, setInputCategory]= useState("");
    const [inputSubCategory, setInputSubCategory]= useState("");
    const [inputTest, setInputTest]= useState("");
    const [messageSuccess, setMessageSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const [subCategories, setSubCategories] = useState();

    const normalizeInput = (value, previousValue) => {
        if (!value) return previousValue;
        let currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        console.log(value,currentValue,previousValue)

        if(currentValue.slice(0, 3) != '994') currentValue = '994'+ currentValue;

        if(value.length > 17) return previousValue;
        if(value.length < 6 && value.length < previousValue.length) return "";

        if (cvLength < 6) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        if (cvLength < 9) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 5)} ${currentValue.slice(5, 8)}`;
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 5)} ${currentValue.slice(5, 8)} ${currentValue.slice(8)}`;       
      };
      
      const validateInput = value => {
        let error = ""
        
        if (!value) error = "Required!"
        else if (value.length !== 14) error = "Invalid phone format. ex: (555) 555-5555";
        
        return error;
      };

    const handleCategoryChange = (value) =>{
        const selected = find(categories,c=>c.name == value)
        setInputCategory(value)
        selected?.subs?.length > 0 ? setSubCategories(selected?.subs) : setSubCategories(null)
        setInputSubCategory('')
    }

    const handleSubmit =(e)=>{
        setLoading(true)
        e.preventDefault()
       
        let inpVlaue = {}
        inpVlaue.name = inputName
        inpVlaue.surname = inputSurname
        inpVlaue.email = inputEmail
        inpVlaue.phonenumber = inputPhone
        inpVlaue.category = inputCategory+" "+inputSubCategory
        inpVlaue.text = inputTest
              
        const  fetchApi=()=>{
            axios({
                method: "POST", 
                url:`${baseUrl}api/email`, 
                data:  inpVlaue
              })
              .then(res=>{
                if(res.status === 200){
                    setInputName("");
                    setInputSurname("");
                    setInputEmail("");
                    setInputPhone("");
                    setInputCategory("");
                    setInputSubCategory("");
                    setInputTest("");
                    setMessageSuccess(true)
                }
              })
              .finally(()=>{
                setLoading(false)
              })
          }
          fetchApi()                 
    }
    return (
        <>
           <form action="#" className="wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".3s"  onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <label htmlFor="name">
                            <select id="category" className="inputs" onChange={(e)=>{handleCategoryChange(e.target.value)}} required value={inputCategory}  >
                                <option value=''>{t("Contact.Category")}*</option>
                                {
                                    map(categories,(c,index)=>(<option value={c.name} key={index}>{c.name}</option>))
                                }                           
                            </select>
                        </label>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <label htmlFor="name">
                            {
                                subCategories && 
                                <select id="subCategory" className="inputs" onChange={(e)=>{setInputSubCategory(e.target.value)}} required value={inputSubCategory}>
                                    <option value=''>{t("Contact.SubCategory")}*</option> 
                                    {
                                        map(subCategories,(c,index)=>(<option value={c.name} key={index}>{c.name}</option>))
                                    }                            
                                </select>
                            }                        
                        </label>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <label htmlFor="name">
                            <input type="text" id="name" placeholder={t("Contact.Name")+"*"} className="inputs" onChange={(e)=>{setInputName(e.target.value)}} required value={inputName} />
                        </label>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <label htmlFor="surname">
                            <input type="text" id="surname" placeholder={t("Contact.Surname")} className="inputs" onChange={(e)=>{setInputSurname(e.target.value)}} value={inputSurname} />
                        </label>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <label htmlFor="mail">
                            <input type="text" id="mail" placeholder={t("Contact.Email")} className="inputs"  onChange={(e)=>{setInputEmail(e.target.value)}} value={inputEmail} />
                        </label>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <label htmlFor="phn">
                            <input type="tell" id="phn" placeholder={t("Contact.Phone")+"*"} className="inputs" onChange={(e)=>{setInputPhone(normalizeInput(e.target.value,inputPhone))}} required minlength="17" value={inputPhone}/>
                        </label>
                    </div>                   
                    <div className="col-lg-12">
                        <label htmlFor="msg">
                            <textarea id="msg" placeholder={t("Contact.Text")} className="inputs" onChange={(e)=>{setInputTest(e.target.value)}} value={inputTest}></textarea>
                        </label>
                    </div>
                    <div className="col-lg-12">
                        <button type="submit" className="btn-style">
                           <span> {loading ? <Spinner/> : t("Contact.Send")}  </span>                          
                        </button>
                        {messageSuccess ? <p className="email-success">{t("Contact.SendSuccess")}</p>: " " }                        
                    </div>
                </div>
            </form> 
        </>
    );
}

export default Forms;
